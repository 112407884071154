@import "tailwindcss/base";

@import "tailwindcss/components";
@import "components.css";

@import "tailwindcss/utilities";
@import "utilities.css";

@import "transitions.css";

h1 {
  margin:0;

}
.swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: #FFF;
    opacity: 0.5;
}
.swiper-pagination-bullet-active {
  background: #FFF;
  opacity: 1;
}


