/* purgecss start ignore */
@media(hover: hover) and (pointer: fine) {

  .project-card::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 3px 0px rgba(151,111,50,0.5);
    opacity: 0;
    transition: opacity 500ms;
  }

  .project-card:hover::before{
    opacity: 1;
  }

  .project-card:hover {
    transition: transform 500ms;
    transform: scale(1.01);
  }
}

@responsive {

  .video-title {
    font-size: clamp(1.5rem, 6vw, 5.25rem);
    text-shadow: 0 0 12px rgba(0,0,0,0.66);
  }

  .shadow-inner-md {
    box-shadow: inset 3px 3px 3px -3px rgba(0,0,0,1);
  }
  .h-page {
    height : calc( 100vh - 4.5rem );
  }
  .top-nav {
    z-index: 5000;
    box-shadow: 0px 0px 3px 0px rgba(151,111,50,0.5);
  }
   .home.top-nav {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
  }
  .home.top-nav:hover {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 3px 0px rgba(151,111,50,0.5);
  }
  .sub-nav {
    box-shadow: 0px 0px 1px 0px rgba(151,111,50,0.7);
  }


 .golden-shadow {
     box-shadow: 0px 0px 3px 0px rgba(151,111,50,0.5);
 }
 .golden-border {
     box-shadow: 0px -1px 3px 0px rgba(151,111,50,0.5);
 }
 .google-shadow {
   box-shadow: 0 1px 5px 0 rgba(151,111,50,0.16),0 1px 2px 0 rgba(151,111,50,0.26);
 }
 .vertical-text {
    transform: rotate(90deg);
  }

  .flex-break {
    flex-basis: 100%;
    height: 0;
  }

  .simple-scroll {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
.simple-scroll::-webkit-scrollbar {
  display: none;
}

.duration-2500 {
  transition-duration: 2500ms;
}
.delay-2500 {
  transition-delay:2500ms;
}
  .background-video {
    @apply absolute inset-0 w-full h-full;
    @apply object-cover bg-center bg-cover;
  }
  .x2 {
    transform: scale(2.5);
    transform: scale(2.5) translateY(4rem);
  }
  .transition {
    transition: all 1s;
  }
  .transition-delay {
    transition-delay: 1s;
    transition: all 3s;
  }
  .transition-slow {
    transition: all 2s;
  }

.backlight-transition {
  transition-delay: 0ms;
  transition-duration: 300ms;
}

.backlight-on{
  @apply opacity-100;
  transition-delay: 0ms;
  transition-duration: 600ms;
}
.flex select {
  -webkit-appearance: none;
-moz-appearance: none;
appearance: none;
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
background-position: right 0.5rem center;
background-repeat: no-repeat;
background-size: 1.5em 1.5em;
}
  .flex label {
    @apply p-3 bg-gray-100 w-32;
  }
  .flex input, .flex textarea {
    @apply flex-1 pl-3 border-l border-gray-300;
  }
  .flex textarea {
    @apply pt-3;
  }

.subnav.active {
   @apply text-gold font-bold italic;
}
.subnav.standby {
   @apply text-gold italic;
}

.pink-gradient {
    background: linear-gradient(0deg, rgba(159,4,27,1) 0%, rgba(241,66,81,0.93) 50%, rgba(159,4,27,1) 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
     background-clip: text;
    -webkit-text-fill-color: transparent;
}

  .gold-gradient {
    background: linear-gradient(0deg, rgba(200,168,97,1) 0%, rgba(151,111,50,0.5) 50%, rgba(200,168,97,1) 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
     background-clip: text;
    -webkit-text-fill-color: transparent;
   }

   .gold-title span {
     background: linear-gradient(0deg, rgba(200,168,97,1) 0%, rgba(151,111,50,0.5) 50%, rgba(200,168,97,1) 100%);
     background-position-x: 100%;
     background-position-y: 38px;
     background-size: 100% 1px;
     background-repeat: repeat-x;
   }

   .gold-title-sm span {
     background: linear-gradient(0deg, rgba(200,168,97,1) 0%, rgba(151,111,50,0.5) 50%, rgba(200,168,97,1) 100%);
     background-position-x: 100%;
     background-position-y: 31px;
     background-size: 100% 1px;
     background-repeat: repeat-x;
   }

   .underline-thin {
     text-decoration: underline;
     text-decoration-thickness: 1px;
     text-underline-offset: 2px;
   }

   .mobile-mb-offset {
     margin-bottom: 33vw;
   }

   .mobile-mt-offset {
     margin-top: 33vw;
   }

   .mobile-pt-offset {
     padding-top:33vw;
   }

   .portfolio-screen-xl-aspect {
      margin-top: 0;
      padding-bottom: 29.5%;
   }

   .portfolio-screen-lg-aspect {
     margin-top: 0;
     padding-bottom: 37.5%;
   }

   .portfolio-screen-aspect {
     margin-top: -66vw;
   }

   .portfolio-screen-offset {
     margin-top:36vw;
   }

   .portfolio-screen-gradient{
     background-image: linear-gradient(170deg, rgba(200,168,97,0.10) 1%, rgba(151,111,50,0.10) 27%, rgba(200,168,97,0.10) 51%);
   }
}


[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
/* purgecss end ignore */
