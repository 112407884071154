.gold-title, .gold-title-sm, .pink-gradient {
  @apply inline-block gold-gradient text-3xl leading-none uppercase font-poynter tracking-normal;
}

@screen sm {
  .gold-title {
    @apply text-4xl;
  }
}

.detail-body {
  @apply font-poynter text-gray-900 text-base font-normal;
}

@screen sm {
  .detail-body {
    @apply text-sm;
  }
}

