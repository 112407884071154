/* purgecss start ignore */
@responsive {

  .delayed-fade-in-enter-active, .delayed-fade-in-leave-active {
    @apply transition-all duration-1000 delay-1000;
  }

  .delayed-fade-in-enter, .delayed-fade-in-leave-to {
    @apply opacity-0;
  }

  .delayed-fade-in-enter-to, .delayed-fade-in-leave {
    @apply opacity-100;
  }

}
/* purgecss end ignore */
